<script setup>
import { RouterLink, RouterView, useRoute } from 'vue-router'
import { Menu, X } from 'lucide-vue-next'
import OrganizationSwitcher from '@/components/Header/OrganizationSwitcher.vue'
import ProfileDropdown from '@/components/Header/ProfileDropdown.vue'
import { computed, inject, onMounted, ref } from 'vue'
import { useLocalStorage, useTitle } from '@vueuse/core'
import { useIntercept } from '@/composables/interceptFetch.js'
import ToastList from '@/components/ToastList.vue'
import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
import { useAPIFetch } from '@/composables/apiFetch.js'
import { useUsersStore } from '@/stores/users.js'
import getEnv from '@/utils/env.js'
import { hexToRgb } from '@/components/Settings/Branding/color-transformers.js'
import GlobalSearch from '@/components/Header/GlobalSearch.vue'
import ServiceStatus from '@/components/ServiceStatus/ServiceStatus.vue'
import DevToolbar from '@/components/utils/DevToolbar.vue'
import ScrollToTop from '@/components/utils/ScrollToTop.vue'

const route = useRoute()
const oidc = inject('oidc')
const canSeeSettings = inject('canSeeSettings')
const menuOpen = ref(false)

const { data: brand, isFetching } = useAPIFetch('configuration/branding', {
  afterFetch(brand) {
    useTitle(brand.name)

    document.documentElement.style.setProperty('--brand-bg', hexToRgb(brand.colors.bg).join(' '))
    document.documentElement.style.setProperty(
      '--brand-extra-light',
      hexToRgb(brand.colors['extra-light']).join(' ')
    )
    document.documentElement.style.setProperty(
      '--brand-light',
      hexToRgb(brand.colors.light).join(' ')
    )
    document.documentElement.style.setProperty(
      '--brand-default',
      hexToRgb(brand.colors.default).join(' ')
    )
    document.documentElement.style.setProperty(
      '--brand-dark',
      hexToRgb(brand.colors.dark).join(' ')
    )

    let mimePart = brand.logo.split(';')

    if (Array.isArray(mimePart) && mimePart.length > 1) {
      brand.logoMimeType = mimePart[0].replace('data:', '')

      if (brand.logoMimeType === 'image/svg+xml') {
        brand.logoIsSVG = true
      }
    }

    return brand
  },
  immediate: true
})
const usersStore = useUsersStore()
const { isFetching: isUserFetching } = useAPIFetch('user', {
  afterFetch(data) {
    usersStore.setUsers(data.items)
    return data
  },
  immediate: true
})
const loading = computed(() => {
  return isFetching.value || isUserFetching.value
})
const fullWide = useLocalStorage('fullWide', false)
const intercept = useLocalStorage('intercept', false)
const isLocalhost = computed(() => window.location.hostname === 'localhost')
const screenshotMode = false

onMounted(() => {
  if (getEnv('DEV') && intercept.value === true) {
    useIntercept()
  }
})
</script>

<template>
  <LoadingOverlay v-if="loading" />
  <template v-else>
    <div
      v-if="
        !screenshotMode &&
        isLocalhost &&
        getEnv('VITE_EPCAML_API_URL') === 'https://api.epcaml-non-prod.epliance.cloud'
      "
      class="w-full bg-amber-500 py-1 text-center font-bold text-amber-700 text-sm"
    >
      <p>Accessing Staging API on localhost</p>
    </div>
    <div
      v-if="
        !screenshotMode &&
        isLocalhost &&
        getEnv('VITE_EPCAML_API_URL') === 'https://api.epcaml.epliance.cloud'
      "
      class="w-full bg-rose-500 py-1 text-center font-bold text-white text-sm"
    >
      <p>Accessing Production API on localhost</p>
    </div>
    <header class="bg-brand text-white dark:bg-brand-dark print:hidden">
      <div class="container mx-auto flex items-center justify-between p-2 lg:p-3">
        <div class="flex items-center gap-6">
          <RouterLink :to="{ name: 'dashboard' }">
            <img
              v-if="brand"
              :src="brand.logo"
              alt="brand-logo"
              id="brand-logo"
              class="h-5 lg:h-6"
            />
            <img
              v-else
              src="@/assets/epliance_logo_white.svg"
              id="brand-logo"
              alt="brand-logo"
              class="h-6 lg:h-8"
            />
          </RouterLink>
          <OrganizationSwitcher class="hidden lg:block" />
          <nav class="items-center font-bold hidden lg:flex">
            <RouterLink
              :to="{ name: 'dashboard' }"
              class="border border-transparent hover:border-brand-extra-light hover:bg-brand-light dark:hover:bg-brand py-2 px-4 rounded"
              >{{ $t('dashboard') }}</RouterLink
            >
            <RouterLink
              :to="{ name: 'customers' }"
              class="border border-transparent hover:border-brand-extra-light hover:bg-brand-light dark:hover:bg-brand py-2 px-4 rounded"
              >{{ $t('customers') }}</RouterLink
            >
            <RouterLink
              :to="{ name: 'tasks' }"
              class="border border-transparent hover:border-brand-extra-light hover:bg-brand-light dark:hover:bg-brand py-2 px-4 rounded"
              >{{ $t('tasks') }}</RouterLink
            >
            <RouterLink
              v-if="canSeeSettings"
              :to="{ name: 'settings' }"
              class="border border-transparent hover:border-brand-extra-light hover:bg-brand-light dark:hover:bg-brand py-2 px-4 rounded"
              >{{ $t('settings') }}</RouterLink
            >
          </nav>
        </div>
        <GlobalSearch v-if="route.name !== 'searchResult'" />
        <div class="flex items-center gap-6">
          <ServiceStatus class="hidden lg:block" />
          <ProfileDropdown class="hidden lg:block" />
          <button type="button" class="lg:hidden p-2" @click="menuOpen = !menuOpen">
            <X v-if="menuOpen" :size="20" />
            <Menu v-else :size="20" />
          </button>
        </div>
      </div>
    </header>
    <div
      v-if="menuOpen"
      class="absolute z-40 w-full left-0 bg-brand text-white border-t border-t-brand-light rounded-b shadow-lg overflow-hidden lg:hidden"
    >
      <ul>
        <li class="flex justify-center pt-3">
          <OrganizationSwitcher
            :as-select="true"
            class="w-full mx-3 bg-brand border-brand-extra-light"
          />
        </li>
        <li>
          <RouterLink
            :to="{ name: 'dashboard' }"
            class="text-center block py-2"
            @click="menuOpen = false"
            >{{ $t('dashboard') }}</RouterLink
          >
        </li>
        <li>
          <RouterLink
            :to="{ name: 'customers' }"
            class="text-center block py-2"
            @click="menuOpen = false"
            >{{ $t('customers') }}</RouterLink
          >
        </li>
        <li>
          <RouterLink
            :to="{ name: 'tasks' }"
            class="text-center block py-2"
            @click="menuOpen = false"
            >{{ $t('tasks') }}</RouterLink
          >
        </li>
        <li>
          <RouterLink
            v-if="canSeeSettings"
            :to="{ name: 'settings' }"
            class="text-center block py-2"
            @click="menuOpen = false"
            >{{ $t('settings') }}</RouterLink
          >
        </li>
        <li>
          <hr class="border-brand-extra-light" />
        </li>
        <li>
          <RouterLink
            :to="{ name: 'account' }"
            class="text-center block py-2"
            @click="menuOpen = false"
            >{{ $t('account') }}</RouterLink
          >
        </li>
        <li>
          <button
            type="button"
            class="text-center w-full block py-2"
            @click="oidc.signoutRedirect()"
          >
            {{ $t('logout') }}
          </button>
        </li>
      </ul>
    </div>

    <div
      v-if="route.meta.pageTitle"
      class="bg-brand-light dark:bg-brand text-white border-b border-b-brand-extra-light dark:border-b-brand-light"
    >
      <div class="container mx-auto p-1 md:p-2 lg:p-3 text-center md:text-left">
        <h1 class="font-semibold">{{ $t(route.meta.pageTitle) }}</h1>
      </div>
    </div>
    <hr v-else class="border-brand-bg dark:border-brand-light print:hidden" />

    <div>
      <main class="print:!w-full print:mx-0 p-1 lg:p-3" :class="{ 'container mx-auto': !fullWide }">
        <Suspense>
          <RouterView :key="route.name" />
        </Suspense>
      </main>
    </div>

    <ToastList />
    <ScrollToTop />
    <DevToolbar v-if="!screenshotMode && getEnv('DEV')" />
  </template>
</template>
